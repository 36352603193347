import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import offers from '../store/offers/offersSlice';
import request from '../store/request/requestSlice';
import catalogs from '../store/catalogs/catalogsSlice';
import base from '../store/base/baseSlice';

export const store = configureStore({
    reducer: {
        base,
        offers,
        request,
        catalogs,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
