import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../app/store";
import axios from "axios";
import { selectorBaseUrl } from "../base/baseSlice";

export interface RequestFormBanks {
    [bankId: number]: number; // стоимость предложения по банку
}

export interface RequestFormValues {
    contactName: string;
    amount: string | number;
    inn: string;
    phone: string;
    email?: string;
    auctionNumber?: string;
    isCommercialAuction?: boolean;
    comment?: string;
    banks?: RequestFormBanks;
    productId?: number;
    days?: number;
    licenseAgreement: boolean;
    consentProcessingPersonalData: boolean;
}

interface RequestSliceState {
    isLoading: boolean;
    currentRequest: RequestFormValues | null;
    lastOrderId: string | null;
    isSuccess: boolean;
    isError: boolean;
    error: string | null;
}

const initialState: RequestSliceState = {
    isLoading: false,
    currentRequest: null,
    lastOrderId: null,
    isSuccess: false,
    isError: false,
    error: null,
};

export const requestSlice = createSlice({
    name: 'request',
    initialState,
    reducers: {
        requestSetIsLoadingAction: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        requestSetIsSuccessAction: (state, action: PayloadAction<boolean>) => {
            state.isSuccess = action.payload;
        },
        requestSetIsErrorAction: (state, action: PayloadAction<boolean>) => {
            state.isError = action.payload;
        },
        requestSetCurrentRequestAction: (state, action: PayloadAction<RequestFormValues | null>) => {
            state.currentRequest = action.payload;
        },
        requestSetLastOrderId: (state, action: PayloadAction<string | null>) => {
            state.lastOrderId = action.payload;
        },
        requestSetErrorAction: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
    },
});

export const {
    requestSetIsLoadingAction,
    requestSetCurrentRequestAction,
    requestSetLastOrderId,
    requestSetIsSuccessAction,
    requestSetIsErrorAction,
    requestSetErrorAction,
} = requestSlice.actions;

export const selectorRequestIsLoading = (state: RootState) => state.request.isLoading;
export const selectorRequestCurrentRequest = (state: RootState) => state.request.currentRequest;
export const selectorRequestLastOrderId = (state: RootState) => state.request.lastOrderId;
export const selectorRequestIsSuccess = (state: RootState) => state.request.isSuccess;
export const selectorRequestIsError = (state: RootState) => state.request.isError;
export const selectorRequestError = (state: RootState) => state.request.error;

interface RequestSendThunkProps {
    url: string;
    data: RequestFormValues;
}

export const requestSendThunk = (props: RequestSendThunkProps): AppThunk => (dispatch, getState) => {
    const state = getState();

    const isLoading = selectorRequestIsLoading(state);
    const baseUrl = selectorBaseUrl(state);

    if (isLoading) {
        return;
    }

    const {
        url,
        data: orderData,
    } = props;

    dispatch(requestSetIsLoadingAction(true));
    dispatch(requestSetIsSuccessAction(false));
    dispatch(requestSetIsErrorAction(false));
    dispatch(requestSetLastOrderId(null));

    axios.get(`${baseUrl}/api/token/?type=ORDER_FORM`)
        .then(({ data }) => {
            const {
                token,
                error,
            } = data;

            if (error) {
                return new Promise((res, rej) => {
                    rej('TOKEN_ERROR');
                });
            }

            let telegramUserId = 0;
            let telegramUserInfo = {};

            // @ts-ignore
            const TWA = window?.Telegram?.WebApp;

            if (TWA?.initData) {
                const searchParams = new URLSearchParams(TWA.initData);

                try {
                    const userInfo = searchParams.get('user');
    
                    if (userInfo) {
                        telegramUserInfo = userInfo;
                        
                        const { id } = JSON.parse(userInfo);

                        telegramUserId = id;
                    }
                } catch (error) {}
            }

            return axios.post(url, {
                ...orderData,
                telegramUserId,
                telegramUserInfo,
                token,
            });
        })
        // @ts-ignore
        .then(({ data }) => {
            const {
                orderId,
                error,
            } = data;

            if (error) {
                dispatch(requestSetIsErrorAction(true));

                return;
            }

            dispatch(requestSetIsSuccessAction(true));
            dispatch(requestSetLastOrderId(orderId));
        })
    .catch((error) => {
        dispatch(requestSetIsErrorAction(true));

        console.error(error);
    })
    .finally(() => {
        dispatch(requestSetIsLoadingAction(false));
    });
}

export default requestSlice.reducer;