import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../app/store";

interface BaseSliceState {
    url: string;
}

const initialState: BaseSliceState = {
    url: '',
}

const baseSlice = createSlice({
    name: 'base',
    initialState,
    reducers: {
        baseSetUrlAction: (state, action: PayloadAction<string>) => {
            state.url = action.payload;
        },
    }
});

export const {
    baseSetUrlAction,
} = baseSlice.actions;

export const selectorBaseUrl = (state: RootState) => state.base.url;

export default baseSlice.reducer;