import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';

import './index.scss';
import './inputs.scss';
import './buttons.scss';
import './table.scss';
import './assets/typografy.css';
import CreditForm from './components/credit-form/CreditForm';
import OffersList from './components/offers-list/OffersList';
import { baseSetUrlAction } from './store/base/baseSlice';
import HtmlModal from './components/html-modal/HtmlModal';

const containerCreditForm = document.getElementById('credit-calculator__form');

if (containerCreditForm) {
    const creditForm = createRoot(containerCreditForm);

    let productId;
    let amount;
    let days;
    let baseUrl = '';

    try {
        productId = Number(containerCreditForm.getAttribute('data-product-id'));
    } catch (error) { }

    try {
        amount = Number(containerCreditForm.getAttribute('data-amount'));
    } catch (error) { }

    try {
        days = Number(containerCreditForm.getAttribute('data-days'));
    } catch (error) { }

    try {
        baseUrl = containerCreditForm.getAttribute('data-baseurl') || '';
    } catch (error) { }

    store.dispatch(baseSetUrlAction(baseUrl));

    const isProductHidden = !!containerCreditForm.getAttribute('data-is-propduct-hidden');

    creditForm.render(
        <Provider store={store}>
            <React.StrictMode>
                <CreditForm
                    isProductHidden={isProductHidden}
                    productId={productId || 1}
                    amount={amount || undefined}
                    days={days || undefined}
                />
            </React.StrictMode>
        </Provider>
    );
}

const containerOffersList = document.getElementById('credit-calculator__offers-list');

if (containerOffersList) {
    const offersList = createRoot(containerOffersList);

    let licenseAgreement = '';
    let consentProcessingPersonalData = '';
    
    try {
        licenseAgreement = containerOffersList.getAttribute('data-license-agreement') || '';
    } catch (error) { }
    
    try {
        consentProcessingPersonalData = containerOffersList.getAttribute('data-consent-processing-personal-data') || '';
    } catch (error) { }

    offersList.render(
        <Provider store={store}>
            <React.StrictMode>
                <OffersList
                    licenseAgreement={licenseAgreement || undefined}
                    consentProcessingPersonalData={consentProcessingPersonalData || undefined}
                />
            </React.StrictMode>
        </Provider>
    );
}

document.querySelectorAll('.html-modal').forEach((containerItem) => {
    const id = containerItem.getAttribute('data-id');

    if (!id) {
        return;
    }

    const item = createRoot(containerItem);

    item.render(
        <HtmlModal
            id={id}
            children={containerItem.innerHTML}
        />
    );
});
