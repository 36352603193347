import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { offersGetListThunk, selectorOffersIsLoading } from '../../store/offers/offersSlice';
import { ChangeEvent, useEffect } from 'react';
import moneyFormat from '../../helpers/moneyFormat';
import { selectorCatalogProducts } from '../../store/catalogs/catalogsSlice';
import { selectorBaseUrl } from '../../store/base/baseSlice';

interface CreditFormProps {
    productId?: number;
    amount?: number;
    days?: number;
    isProductHidden?: boolean;
}

const CreditForm = ({
    productId,
    amount,
    days,
    isProductHidden,
}: CreditFormProps) => {
    const dispatch = useAppDispatch();

    const isLoading = useAppSelector(selectorOffersIsLoading);
    const products = useAppSelector(selectorCatalogProducts);
    const baseUrl = useAppSelector(selectorBaseUrl);

    // TODO загрузка списка продуктов

    useEffect(() => {
        if (!productId || !products) {
            return;
        }

        if (products.find(({ id }) => id === productId)) {
            formik.setFieldValue('productId', productId);
        } else {
            formik.setFieldValue('productId', products[0]?.id);
        }
    }, [productId, products]);

    useEffect(() => {
        if (amount !== undefined) {
            formik.setFieldValue('amount', amount);
        }
    }, [amount]);

    useEffect(() => {
        if (days !== undefined) {
            formik.setFieldValue('days', days);
        }
    }, [days]);

    const formik = useFormik({
        initialValues: {
            productId: 0,
            amount: '',
            days: '',
            dayStart: '',
            hasAvans: undefined,
        },
        onSubmit: ({
            productId,
            amount,
            days,
            dayStart,
            hasAvans,
        }) => {
            dispatch(offersGetListThunk({
                url: `${baseUrl}/api/offers/`,
                productId,
                amount: Number(amount.replace(/\ /ig, '')),
                days: Number(days.replace(/\ /ig, '')),
                dayStart,
                hasAvans,
            }));
        },
    });

    const handleNumberFormatChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {
            target: {
                value = '',
                name,
            }
        } = event;

        if (!value) {
            formik.setFieldValue(name, '');

            return;
        }

        let resut = moneyFormat({ value, decimalRound: 2 });

        formik.setFieldValue(name, resut);
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            {!isProductHidden && (
                <div className="input__wrapper">
                    <label>
                        <select
                            name="productId"
                            className="input__element input__element_select"
                            value={formik.values.productId}
                            onChange={formik.handleChange}
                            disabled={isLoading}
                            placeholder=" "
                            required
                        >
                            {products.map(({ id, name }, index) => (
                                <option key={index} value={id}>{name}</option>
                            ))}
                        </select>
                        <div className="input__label">Продукт</div>
                    </label>
                </div>
            )}
            <div className="input__wrapper">
                <label>
                    <input
                        type="text"
                        className="input__element"
                        name="amount"
                        placeholder=" "
                        inputMode="decimal"
                        value={formik.values.amount}
                        onChange={handleNumberFormatChange}
                        disabled={isLoading}
                        required
                    />
                    <div className="input__label">Сумма (в рублях)</div>
                </label>
            </div>
            <div className="input__wrapper">
                <label>
                    <input
                        type="text"
                        className="input__element"
                        name="days"
                        placeholder=" "
                        inputMode="numeric"
                        value={formik.values.days.toString()}
                        onChange={handleNumberFormatChange}
                        disabled={isLoading}
                        required
                    />
                    <div className="input__label">Срок (в днях)</div>
                </label>
            </div>
            <div className="text-center">
                <button
                    type="submit"
                    className="button button_greeen"
                    disabled={isLoading}
                >Посчитать</button>
            </div>
        </form>
    );
}

export default CreditForm;