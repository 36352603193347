import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Offer, selectorOffersCurrentRequest, selectorOffersIsLoading, selectorOffersList } from "../../store/offers/offersSlice";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import moneyFormat from "../../helpers/moneyFormat";
import RequestForm from "../request-form/RequestForm";
import { RequestFormBanks, RequestFormValues, requestSendThunk, selectorRequestIsError, selectorRequestIsSuccess, selectorRequestLastOrderId } from "../../store/request/requestSlice";
import { catalogsLoadBanksThunk, catalogsLoadProductsThunk } from "../../store/catalogs/catalogsSlice";
import Modal from "../modal/Modal";
import styles from './OffersList.module.scss';
import { selectorBaseUrl } from "../../store/base/baseSlice";

interface OffersListForkValuesCurrentBanks {
    [bankId: number]: boolean;
}

interface OffersListForkValues {
    isAllChecked: boolean;
    banks: OffersListForkValuesCurrentBanks;
}

const initialValues: OffersListForkValues = {
    isAllChecked: true,
    banks: {},
}

interface OffersListProps {
    licenseAgreement?: string;
    consentProcessingPersonalData?: string;
}

const OffersList = ({
    licenseAgreement,
    consentProcessingPersonalData,
}: OffersListProps) => {
    const dispatch = useAppDispatch();

    const isLoading = useAppSelector(selectorOffersIsLoading);
    const offers = useAppSelector(selectorOffersList);
    const currentRequest = useAppSelector(selectorOffersCurrentRequest);
    const orderId = useAppSelector(selectorRequestLastOrderId);
    const isOrderSuccess = useAppSelector(selectorRequestIsSuccess);
    const isOrderError = useAppSelector(selectorRequestIsError);
    const baseUrl = useAppSelector(selectorBaseUrl);

    const wrapperRef = useRef<HTMLDivElement>(null);
    // const offersRef = useRef<HTMLDivElement>(null);

    const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(false);
    const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
    const [isSuccessDisplayed, setIsSuccessDisplayed] = useState<boolean>(false);
    const [isSortByASC, setIsSortByASC] = useState<boolean | null>(null);
    const [offersSorted, setOffersSorted] = useState<Offer[]>([]);

    const isListEmpty = !offers.length;

    const formik = useFormik({
        initialValues,
        onSubmit: ({ banks = {} }) => {
            if (!Object.values(banks).find((value) => value)) {
                alert('Так тыж ничего не выбрал!');

                return;
            }

            setIsFormDisplayed(true);
            setIsErrorDisplayed(false);
            setIsSuccessDisplayed(false);
        },
    });

    useEffect(() => {
        dispatch(catalogsLoadBanksThunk({ url: `${baseUrl}/api/banks/` }));
        dispatch(catalogsLoadProductsThunk({ url: `${baseUrl}/api/products/` }));
    }, []);

    useEffect(() => {
        if (isOrderError) {
            setIsErrorDisplayed(true);
        }
    }, [isOrderError]);

    useEffect(() => {
        if (isOrderSuccess) {
            setIsSuccessDisplayed(true);

            setIsFormDisplayed(false);
        }
    }, [isOrderSuccess]);

    useEffect(() => {
        formik.resetForm();

        if (isListEmpty) {
            setOffersSorted([]);
            wrapperRef.current?.parentElement?.parentElement?.scrollIntoView();

            return;
        }

        const banks: OffersListForkValuesCurrentBanks = {};

        offers.forEach(({ bankId }) => {
            banks[bankId] = true;
        });

        formik.setFieldValue('banks', banks);
        formik.setFieldValue('isAllChecked', true);

        setOffersSorted([...offers].sort(({ cost: costA }, { cost: costB }) => {
            if (isSortByASC === null) {
                return 0;
            }

            return (isSortByASC)
                ? costA - costB
                : costB - costA;
        }));
    }, [
        offers,
        isListEmpty,
        isSortByASC,
    ]);

    const handleIsAlCheckedClick = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('isAllChecked', event.target.checked);

        Object.keys(formik.values.banks)
            .forEach((bankId) => {
                formik.setFieldValue(`banks[${bankId}]`, event.target.checked);
            });
    }

    const handleSubmit = (data: RequestFormValues) => {
        const acceptBankIds: number[] = Object.entries(formik.values.banks)
            .filter(([id, status]) => status)
            .map(([id]) => Number(id));

        const banks: RequestFormBanks = offers.reduce((acc, {
            bankId,
            cost,
        }) => {
            if (!acceptBankIds.includes(Number(bankId))) {
                return acc;
            }

            return {
                ...acc,
                [bankId]: cost,
            };
        }, {});

        const {
            productId = 0,
            amount = 0,
            days = 0,
        } = currentRequest || {};

        dispatch(requestSendThunk({
            url: `${baseUrl}/api/orders/`,
            data: {
                ...data,
                banks,
                productId,
                amount,
                days,
            },
        }));
    }

    if (!isLoading && isListEmpty) {
        return null;
    }

    return (
        <div ref={wrapperRef}>
            {isLoading && (
                <div className="text-center text-heading_lg">Загрузка...</div>
            )}

            {!isListEmpty && (
                <div>
                    <div className="text-bold text-heading_lg">Результаты</div>
                    <form method="POST" onSubmit={formik.handleSubmit}>
                        {(offersSorted?.length > 3) && (
                            <div className={styles.formHeader}>
                                <div className={styles.cell}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="isAllChecked"
                                            checked={!!formik.values.isAllChecked}
                                            onChange={handleIsAlCheckedClick}
                                        />
                                        Выбрать всё
                                    </label>
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        className="button button_greeen"
                                    >Отправить заявку</button>
                                </div>
                            </div>
                        )}
                        <table className="table" border={1}>
                            <thead>
                                <tr>
                                    <th>Банк</th>
                                    <th>
                                        <span
                                            className={styles.offersSort}
                                            onClick={() => setIsSortByASC(!isSortByASC)}
                                        >
                                            Стоимость&nbsp;{isSortByASC ? '▲' : '▼'}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {offersSorted.map(({
                                    bankId,
                                    bankName,
                                    bankLogo,
                                    cost,
                                    percent,
                                }, index) => (
                                    <tr key={index}>
                                        <td>
                                            <label className={styles.bankItem}>

                                                <input
                                                    type="checkbox"
                                                    name={`banks[${bankId}]`}
                                                    checked={!!formik.values.banks[bankId]}
                                                    onChange={formik.handleChange}
                                                />
                                                {!!bankLogo && (
                                                    <img src={`https://rocketfin.ru${bankLogo}`} />
                                                )}
                                                {bankName}
                                            </label>
                                        </td>
                                        <td>
                                            {moneyFormat({ value: cost })}&nbsp;Р<br />
                                            <strong className="text-size_sm">{percent}%</strong>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={styles.bottomSubmitWrapper}>
                            <button
                                type="submit"
                                className="button button_greeen"
                            >Отправить заявку</button>
                        </div>
                    </form>
                </div>
            )}
            {isFormDisplayed && (
                <Modal onClose={() => setIsFormDisplayed(false)}>
                    <RequestForm
                        amount={currentRequest?.amount}
                        isAmountDisabled={true}
                        isBg={[6,7,8,9].includes(Number(currentRequest?.productId))}
                        onSubmit={handleSubmit}
                        licenseAgreement={licenseAgreement}
                        consentProcessingPersonalData={consentProcessingPersonalData}
                    />
                </Modal>
            )}
            {isSuccessDisplayed && (
                <Modal onClose={() => setIsSuccessDisplayed(false)}>
                    <div className="text-center">
                        <div className="text-heading_lg text-bold">Заявка успешно отправлена!</div>
                        <div className="text-size_lg">Номер заявки: <strong>{orderId}</strong></div>
                    </div>
                </Modal>
            )}
            {isErrorDisplayed && (
                <Modal onClose={() => setIsErrorDisplayed(false)}>
                    <div>
                        <div>Произошла ошибка при отправке заявки</div>
                        <div>Вы можете связаться с нами по этому номеру</div>
                        <div>
                            <a href="tel:88001112233">8 (800) 111-22-33</a>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default OffersList;