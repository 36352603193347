interface MoneyFormatProps {
    value: number | string;
    decimalRound?: number;
}

const moneyFormat = ({
    value,
    decimalRound,
}: MoneyFormatProps): string => {
    let [total, decimal] = value.toString()
        .replace(/[^0-9\.,]/g, '')
        .replace(',', '.')
        .split('.');

    let resut = '';

    if (decimal !== undefined) {
        if (decimalRound) {
            decimal = decimal?.slice(0, decimalRound);
        }
        
        resut = '.' + decimal;
    }

    let flagAddSpace = false;

    total = Number(total).toString();

    while (total.length) {
        resut = total.slice(-3) + ((flagAddSpace) ? ' ' : '') + resut;

        if (!flagAddSpace) {
            flagAddSpace = true;
        }

        if (total.length <= 3) {
            total = '';
        } else {
            total = total.slice(0, total.length - 3);
        }
    }

    return resut;
}

export default moneyFormat;