import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../app/store";
import axios from "axios";
import { selectorCatalogBanks } from "../catalogs/catalogsSlice";

interface OfferRequest {
    productId: number;
    amount: number;
    days: number;
    dayStart?: string;
    hasAvans?: boolean;
}

export interface Offer {
    bankId: number;
    bankName: string;
    bankLogo: string;
    cost: number;
    percent: number;
}

interface Tariff {
    bankId: number;
    percent: number;
    minCommission: number;
    ourPercent: number;
}

interface OffersSliceState {
    isLoading: boolean;
    currentRequest: OfferRequest | null;
    list: Offer[];
    error: string | null;
}

const initialState: OffersSliceState = {
    isLoading: false,
    currentRequest: null,
    list: [],
    error: null,
};

export const offersSlice = createSlice({
    name: 'offers',
    initialState,
    reducers: {
        offersSetIsLoadingAction: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        offersSetCurrentRequestAction: (state, action: PayloadAction<OfferRequest | null>) => {
            state.currentRequest = action.payload;
        },
        offersSetListAction: (state, action: PayloadAction<Offer[]>) => {
            state.list = action.payload;
        },
        offersClearAction: (state) => {
            state.currentRequest = null;
            state.list = [];
            state.error = null;
        },
        offersSetErrorAction: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
    },
});

export const {
    offersSetCurrentRequestAction,
    offersSetIsLoadingAction,
    offersSetListAction,
    offersClearAction,
    offersSetErrorAction,
} = offersSlice.actions;

export const selectorOffersIsLoading = (state: RootState) => state.offers.isLoading;
export const selectorOffersCurrentRequest = (state: RootState) => state.offers.currentRequest;
export const selectorOffersList = (state: RootState) => state.offers.list;
export const selectorOffersError = (state: RootState) => state.offers.error;

interface OffersGetListThunkProps extends OfferRequest {
    url: string;
}

export const offersGetListThunk = ({
    url,
    ...requestData
}: OffersGetListThunkProps): AppThunk => (dispatch, getState) => {
    const state = getState();

    if (selectorOffersIsLoading(state)) {
        return;
    }

    const banks = selectorCatalogBanks(state);

    dispatch(offersSetListAction([]));
    dispatch(offersSetCurrentRequestAction(requestData));
    dispatch(offersSetIsLoadingAction(true));

    axios.get(url, {
        params: {
            token: 'TOKEN',
            ...requestData,
            t: (new Date()).getTime(),
        }
    })
        .then(({ data }) => {
            const {
                amount,
                days,
            } = requestData;

            const list: Tariff[] = data?.list || [];
            

            dispatch(offersSetListAction(list.map(({
                bankId,
                minCommission,
                ourPercent,
                percent,
            }) => {
                const dayPercent = percent / 365;
                const bank = banks.find(({ id }) => id === bankId);
                let cost = Math.max(Number(minCommission), (amount * days * Number(dayPercent) / 100)) * (100 + Number(ourPercent)) / 100;

                // округление до 100
                cost = Math.ceil(cost / 100) * 100;

                return {
                    bankId,
                    bankName: bank?.name || `Название не найдено ${bankId}`,
                    bankLogo: bank?.logo || '',
                    cost,
                    percent,
                }
            })));
        })
        .catch((error) => {
            console.log('offersGetListThunk|error', error);
        })
        .finally(() => {
            dispatch(offersSetIsLoadingAction(false));
        });

    // TODO test
    if (true) {
        return;
    }
}

export default offersSlice.reducer;