import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../app/store";
import axios from "axios";

export interface Banks {
    id: number;
    name: string;
    logo: string;
}

export interface Products {
    id: number;
    name: string;
    description: string;
}

interface OffersSliceState {
    isLoadingBanks: boolean;
    banks: Banks[];
    isLoadingProducts: boolean;
    products: Products[];
}

const initialState: OffersSliceState = {
    isLoadingBanks: false,
    banks: [],
    isLoadingProducts: false,
    products: [],
};

const catalogsSlice = createSlice({
    name: 'catalogs',
    initialState,
    reducers: {
        catalogsSetIsLoadingBanksAction: (state, action: PayloadAction<boolean>) => {
            state.isLoadingBanks = action.payload;
        },
        catalogsSetBanksAction: (state, action: PayloadAction<Banks[]>) => {
            state.banks = action.payload;
        },
        catalogsSetIsLoadingProductsAction: (state, action: PayloadAction<boolean>) => {
            state.isLoadingProducts = action.payload;
        },
        catalogsSetProductsAction: (state, action: PayloadAction<Products[]>) => {
            state.products = action.payload;
        },
    },
});

export const {
    catalogsSetIsLoadingBanksAction,
    catalogsSetBanksAction,
    catalogsSetIsLoadingProductsAction,
    catalogsSetProductsAction,
} = catalogsSlice.actions;

export const selectorCatalogIsLoadingBanks = (state: RootState) => state.catalogs.isLoadingBanks;
export const selectorCatalogBanks = (state: RootState) => state.catalogs.banks;
export const selectorCatalogIsLoadingProducts = (state: RootState) => state.catalogs.isLoadingProducts;
export const selectorCatalogProducts = (state: RootState) => state.catalogs.products;

interface CatalogsLoadBanksThunkProps {
    url: string;
}

export const catalogsLoadBanksThunk = ({
    url,
}: CatalogsLoadBanksThunkProps): AppThunk => (dispatch, getState) => {
    const state = getState();

    const isLoading = selectorCatalogIsLoadingBanks(state);
    const banks = selectorCatalogBanks(state);

    if (banks.length || isLoading) {
        return;
    }

    dispatch(catalogsSetIsLoadingBanksAction(true));

    axios.get(url)
        .then(({ data }) => {
            const { list = [] } = data;

            dispatch(catalogsSetBanksAction(list));
        })
        .catch((error) => {
            console.error('catalogsSlice|catalogsLoadBanksThunk|error', error);
        })
        .finally(() => {
            dispatch(catalogsSetIsLoadingBanksAction(false));
        });
}

interface CatalogsLoadProductsThunkProps {
    url: string;
}

export const catalogsLoadProductsThunk = ({
    url,
}: CatalogsLoadProductsThunkProps): AppThunk => (dispatch, getState) => {
    const state = getState();

    const isLoading = selectorCatalogIsLoadingProducts(state);
    const products = selectorCatalogProducts(state);

    if (products.length || isLoading) {
        return;
    }

    dispatch(catalogsSetIsLoadingProductsAction(true));

    axios.get(url)
        .then(({ data }) => {
            const { list = [] } = data;

            dispatch(catalogsSetProductsAction(list));
        })
        .catch((error) => {
            console.error('catalogsSlice|catalogsSetProductsAction|error', error);
        })
        .finally(() => {
            dispatch(catalogsSetIsLoadingProductsAction(false));
        });
}

export default catalogsSlice.reducer;