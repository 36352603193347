import { MouseEventHandler, ReactNode, useEffect, useState } from "react";
import cn from 'classnames';
import styles from './Modal.module.scss';

interface ModalProps {
    children?: ReactNode;
    onClose?: () => void;
    isWide?: boolean;
}

const Modal = ({
    children,
    onClose,
    isWide,
}: ModalProps) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [isContentDisplayed, setIsContentDisplayed] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setIsOpened(true);
        }, 0);
        
        // половина от 480ms синхронится с CSS
        setTimeout(() => {
            setIsContentDisplayed(true);
        }, 240);
    }, []);

    const handleContainerClick: MouseEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation();
    }

    const handleCloseClick = () => {
        setIsContentDisplayed(false);
        setIsOpened(false);

        if (!onClose) {
            return;
        }
        
        // 480ms синхронится с CSS
        setTimeout(() => {
            onClose();
        }, 480);
    }

    return (
        <div
            className={cn(styles.wrapper, {
                [styles.wrapperOpened]: isOpened,
            })}
            onClick={handleCloseClick}
        >
            {isContentDisplayed && (
                <div
                    className={cn(styles.container, {
                        [styles.containerWide]: isWide, 
                    })}
                    onClick={handleContainerClick}
                >
                    <div
                        className={styles.close}
                        onClick={handleCloseClick}
                    />
                    {children}
                </div>
            )}
        </div>
    );
}

export default Modal