import { ReactNode, useEffect, useState } from "react";
import Modal from "../modal/Modal";

interface HtmlModalProps {
    id?: string;
    children?: any;
}

const HtmlModal = ({
    id,
    children,
}: HtmlModalProps) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    useEffect(() => {
        const action = (event: any) => {
            setIsDisabled(true);

            event?.preventDefault();

            return false;
        };

        const buttons = document.querySelectorAll(`[data-button-id="${id}"]`);

        if (!buttons.length) {
            return;
        }

        buttons.forEach((button) => {
            button.addEventListener('click', action);
        });

        return () => {
            buttons.forEach((button) => {
                button.removeEventListener('click', action);
            });
        };
    }, []);

    if (!isDisabled) {
        return null;
    }

    return (
        <Modal
            isWide={true}
            onClose={() => setIsDisabled(false)}
        >
            <div dangerouslySetInnerHTML={{ __html: children }} />
        </Modal>
    );
}

export default HtmlModal;