import { useFormik } from "formik";
import { ChangeEvent, ReactNode, useEffect } from "react";
import moneyFormat from "../../helpers/moneyFormat";
import { RequestFormValues, selectorRequestIsLoading } from "../../store/request/requestSlice";
import { useAppSelector } from "../../app/hooks";
import styles from './RequestForm.module.scss';

const initialValues: RequestFormValues = {
    amount: '',
    auctionNumber: '',
    isCommercialAuction: false,
    contactName: '',
    inn: '',
    phone: '',
    comment: '',
    email: '',
    licenseAgreement: true,
    consentProcessingPersonalData: true,
};

interface RequestFormProps {
    children?: ReactNode;
    isAmountHide?: boolean;
    amountTitle?: string;
    amount?: number;
    isAmountDisabled?: boolean;
    isBg?: boolean;
    licenseAgreement?: string;
    consentProcessingPersonalData?: string;
    onSubmit?: (props: RequestFormValues) => void;
}

const RequestForm = ({
    children,
    isAmountHide,
    amountTitle = 'Сумма займа',
    amount,
    isAmountDisabled,
    isBg,
    licenseAgreement,
    consentProcessingPersonalData,
    onSubmit,
}: RequestFormProps) => {
    const isLoading = useAppSelector(selectorRequestIsLoading);

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            if (onSubmit) {
                onSubmit(values);
            }
        },
    });

    useEffect(() => {
        if (amount === undefined) {
            return;
        }

        formik.setFieldValue('amount', moneyFormat({ value: amount }));
    }, [
        amount,
    ]);

    const handleNumberFormatChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {
            target: {
                value = '',
                name,
            }
        } = event;

        if (!value) {
            formik.setFieldValue(name, '');

            return;
        }

        let result = moneyFormat({ value });

        formik.setFieldValue(name, result);
    }

    const handleInnFormatChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {
            target: {
                value = '',
                name,
            }
        } = event;

        if (!value) {
            formik.setFieldValue(name, '');

            return;
        }

        let result = ((value: string): string => {
            return value.replace(/[^0-9]/ig, '');
        })(value);

        formik.setFieldValue(name, result);
    }

    const isSubmitDisabled = isLoading || !formik.values.licenseAgreement || !formik.values.consentProcessingPersonalData;

    return (
        <div>
            {isLoading && (
                <div className={styles.loading}>
                    Отправка заявки... Ожидайте.
                </div>
            )}
            {!!children && (
                <div>{children}</div>
            )}
            <form method="POST" onSubmit={formik.handleSubmit}>
                {isBg && (
                    <>
                        <div className="input__wrapper">
                            <label>
                                <input
                                    type="text"
                                    className="input__element"
                                    name="auctionNumber"
                                    // inputMode="numeric"
                                    value={formik.values.auctionNumber}
                                    onChange={formik.handleChange}
                                    placeholder="Номер аукциона"
                                    disabled={!!formik.values.isCommercialAuction}
                                    required={!formik.values.isCommercialAuction}
                                />
                                <div className="input__label">Номер аукциона</div>
                            </label>
                        </div>
                        <div className="input__wrapper input__wrapper_checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    name="isCommercialAuction"
                                    checked={formik.values.isCommercialAuction}
                                    onChange={formik.handleChange}
                                />&nbsp;
                                коммерческий аукцион
                            </label>
                        </div>
                    </>
                )}

                {!isAmountHide && (
                    <div className="input__wrapper">
                        <label>
                            <input
                                type="text"
                                className="input__element"
                                name="amount"
                                inputMode="numeric"
                                value={formik.values.amount}
                                onChange={handleNumberFormatChange}
                                placeholder={amountTitle}
                                disabled={isAmountDisabled || isLoading}
                                required
                            />
                            <div className="input__label">{amountTitle}</div>
                        </label>
                    </div>
                )}

                <div className="input__wrapper">
                    <label>
                        <input
                            type="text"
                            className="input__element"
                            name="contactName"
                            value={formik.values.contactName}
                            onChange={formik.handleChange}
                            placeholder=" "
                            disabled={isLoading}
                            required
                        />
                        <div className="input__label">Контактное лицо<sup>*</sup></div>
                    </label>
                </div>

                <div className="input__wrapper">
                    <label>
                        <input
                            type="text"
                            className="input__element"
                            name="inn"
                            inputMode="numeric"
                            value={formik.values.inn}
                            onChange={handleInnFormatChange}
                            placeholder=" "
                            disabled={isLoading}
                            minLength={10}
                            max={12}
                            required
                        />
                        <div className="input__label">ИНН<sup>*</sup></div>
                    </label>
                </div>

                <div className="input__wrapper">
                    <label>
                        <input
                            type="tel"
                            className="input__element"
                            name="phone"
                            inputMode="tel"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            placeholder=" "
                            disabled={isLoading}
                            required
                        />
                        <div className="input__label">Телефон<sup>*</sup></div>
                    </label>
                </div>

                <div className="input__wrapper">
                    <label>
                        <input
                            type="email"
                            className="input__element"
                            name="email"
                            inputMode="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            placeholder=" "
                            disabled={isLoading}
                        />
                        <div className="input__label">E-mail</div>
                    </label>
                </div>

                <div className="input__wrapper">
                    <label>
                        <textarea
                            className="input__element input__element_textarea"
                            name="comment"
                            value={formik.values.comment}
                            onChange={formik.handleChange}
                            disabled={isLoading}
                            placeholder=" "
                        />
                        <div className="input__label">Комментарий</div>
                    </label>
                </div>

                <div className="input__wrapper">
                    <div className="input__agreement">
                        <label>
                            <input
                                type="checkbox"
                                name="licenseAgreement"
                                checked={!!formik.values.licenseAgreement}
                                onChange={formik.handleChange}
                            />&nbsp;
                            я соглашаюсь с <a href={licenseAgreement} target="_blank">лицензионным соглашением</a>
                        </label>
                    </div>

                    <div className="input__agreement">
                        <label>
                            <input
                                type="checkbox"
                                name="consentProcessingPersonalData"
                                checked={!!formik.values.consentProcessingPersonalData}
                                onChange={formik.handleChange}
                            />&nbsp;
                            я соглашаюсь с <a href={consentProcessingPersonalData} target="_blank">политикой обработки персональных данных</a>
                        </label>
                    </div>
                </div>

                <div className="text-center">
                    <button
                        type="submit"
                        className="button button_greeen"
                        disabled={isSubmitDisabled}
                    >Отправить</button>
                </div>
            </form>
        </div>
    );
}

export default RequestForm;